import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CrossCheckNoIssueTable from "./CrossCheckNoIssueTable";
import CrossCheckIssueTable from "./CrossCheckIssueTable";
import CrossCheckContainerNumberTable from "./CrossCheckContainerNumberTable";
import CrossCheckIcoTable from "./CrossCheckIcoTable";

function CrossCheckList({
  type,
  comparedData,
  documentIdPath,
  splitIdPath,
  documentTypePath,
  highlightedIndicies,
  setHighlightedIndicies,
}) {
  const [currentComparedB, setCurrentComparedB] = useState([]);
  const [currentComparedA, setCurrentComparedA] = useState([]);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    if (!documentIdPath || !documentTypePath || !comparedData) return;
    let constructedKey = null;

    const findKeyInData = (data) => {
      for (const key in data) {
        if (key.startsWith(`${documentIdPath}_`)) {
          constructedKey = key;
          return true;
        }
      }
      return false;
    };

    if (!findKeyInData(comparedData)) {
      constructedKey = `${documentIdPath}_${documentIdPath}`;
    }

    if (constructedKey && comparedData?.[constructedKey]) {
      setCurrentComparedB(comparedData?.[constructedKey]?.[splitIdPath]?.compared_b || []);
      setCurrentComparedA(comparedData?.[constructedKey]?.[splitIdPath]?.compared_a || []);
    }
  }, [documentIdPath, splitIdPath, comparedData, documentTypePath, type, location.pathname]);

  const ListHeader = ({ title }) => (
    <div className="font-medium text-sky-800 text-xs py-2 border-t px-2 mb-2">{title}</div>
  );

  return (
    <div className="relative flex w-full flex-col">
      <ListHeader
        title={
          type === "bol"
            ? `v.s. Bill of Lading`
            : type === "di"
            ? `v.s. Document Instruction`
            : type === "lc"
            ? `v.s. Letter of Credit`
            : type === "invoice"
            ? "v.s. Commercial Invoice"
            : "v.s. Sales Contract"
        }
      />
      {Object.values(currentComparedA)?.length === 0 && currentComparedB?.length === 0 && (
        <p className="ml-7 text-xs my-2 text-gray-400">No information to show.</p>
      )}

      <div className="flex flex-col w-full h-full overflow-y-auto space-y-1 pb-3 px-2">
        {currentComparedB?.length > 0 && (
          <div className="space-y-1">
            <CrossCheckIssueTable
              currentComparedB={currentComparedB}
              setHighlightedIndicies={setHighlightedIndicies}
              currentDocumentType={documentTypePath}
              type={type}
              highlightedIndicies={highlightedIndicies}
            />
          </div>
        )}

        {Object.values(currentComparedA)?.length > 0 && (
          <div className="space-y-1">
            <CrossCheckContainerNumberTable
              currentComparedA={currentComparedA}
              currentDocumentType={documentTypePath}
              type={type}
            />
            <CrossCheckIcoTable
              currentComparedA={currentComparedA}
              currentDocumentType={documentTypePath}
              type={type}
            />
          </div>
        )}
        {currentComparedB?.length > 0 && (
          <div className="space-y-1">
            <CrossCheckNoIssueTable
              currentComparedB={currentComparedB}
              setHighlightedIndicies={setHighlightedIndicies}
              currentDocumentType={documentTypePath}
              type={type}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CrossCheckList;

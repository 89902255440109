import React, { useState } from "react";
import { formatDocumentType } from "../../utils/formatDocumentType";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import FolderDashboardHeader from "./FolderDashboard/FolderDashboardHeader";
import AddFilesModal from "../modals/AddFilesModal";
import { FiUpload } from "react-icons/fi";

const TradeDocumentsTable = ({
  files,
  splitterData,
  comparedData,
  comparedInvoiceData,
  contractData,
  comparedLcData,
  comparedDiData,
  projectId,
  comparedFetched,
  archiveInfo,
  splitterFetched,
  initiallyFetched,
  getArchive,
  getFiles,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  const navigate = useNavigate();
  const [isCreateFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  const getDiscrepancies = (
    archiveInfo,
    comparedData,
    comparedInvoiceData,
    contractData,
    comparedLcData,
    comparedDiData,
    splitId
  ) => {
    const status = archiveInfo?.archive_compared_status || {};

    const calculateDiscrepancies = (documentId, splitId, data) => {
      const contractDiscrepancies =
        data?.contractData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const bolDiscrepancies =
        data?.comparedData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const dateDiscrepancy = data?.comparedData?.[documentId]?.[splitId]?.compared_a?.issued_date?.is_error
        ? 1
        : 0;
      const invoiceDiscrepancies =
        data?.comparedInvoiceData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const lcDiscrepancies =
        data?.comparedLcData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const diDiscrepancies =
        data?.comparedDiData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;

      return (
        contractDiscrepancies +
        bolDiscrepancies +
        invoiceDiscrepancies +
        dateDiscrepancy +
        lcDiscrepancies +
        diDiscrepancies
      );
    };

    const discrepancies = Object.entries(status).reduce((acc, [type, statusObj]) => {
      Object.entries(statusObj).forEach(([key, value]) => {
        const constructedKey = `${key}_${value}`;
        const discrepancyCount = calculateDiscrepancies(constructedKey, splitId, {
          contractData,
          comparedData,
          comparedInvoiceData,
          comparedLcData,
          comparedDiData,
        });
        acc[constructedKey] = discrepancyCount;
      });

      return acc;
    }, {});

    return discrepancies;
  };

  const mergeDiscrepancies = (discrepancies) => {
    return Object.entries(discrepancies).reduce((acc, [key, value]) => {
      const documentId = key.split("_")[0];
      acc[documentId] = (acc[documentId] || 0) + value;
      return acc;
    }, {});
  };

  const TableCell = ({ item, documentId, splitId }) => {
    const getExtensionByDocumentId = (documentId) => {
      const file = files.find((file) => file.document_id.toString() === documentId.toString());
      if (file) {
        const extension = file.document_name.split(".").pop();
        return extension;
      }
      return "";
    };

    const initialDiscrepancies = getDiscrepancies(
      archiveInfo,
      comparedData,
      comparedInvoiceData,
      contractData,
      comparedLcData,
      comparedDiData,
      splitId
    );
    const totalDiscrepancies = mergeDiscrepancies(initialDiscrepancies);

    if (item.document_type.includes("contract")) {
      return null; // Skip rendering the cell if document_type includes "contract"
    }

    return (
      <tr
        onClick={() =>
          comparedFetched &&
          navigate(
            `/dashboard/folders/${projectId}/check-results?document_id=${documentId}&split_id=${splitId}&doc_type=${
              item?.document_type
            }&extension=${getExtensionByDocumentId(documentId)}`
          )
        }
        className={`transition text-xs hover:bg-sky-100 cursor-pointer`}
      >
        <td className="px-2 md:px-3 py-3 border-gray-100">
          <div className="flex space-x-2 items-center">
            {!comparedFetched && (
              <AiOutlineLoading3Quarters className="w-3 h-3 animate-spin text-gray-500 flex-shrink-0" />
            )}
            <button
              className={`${
                !comparedFetched ? "text-gray-400 font-normal" : "text-sky-600"
              } hover:underline text-left flex-shrink-0`}
            >
              {item.document_type.includes("bol")
                ? "Bill of Lading"
                : item.document_type.includes("coo_general")
                ? "Certificate of Origin"
                : item.document_type.includes("coo_ico")
                ? "Certificate of Origin (ICO)"
                : item.document_type.includes("coo_ldc")
                ? "Certificate of Origin (LDC)"
                : item.document_type.includes("coo_free_trade")
                ? "Certificate of Origin (FTA)"
                : item.document_type.includes("coo_gsp")
                ? "Certificate of Origin (GSP)"
                : formatDocumentType(item.document_type)}
            </button>
          </div>
        </td>

        <td className="px-2 md:px-3 py-3 space-y-0.5 rounded-tr rounded-br">
          <span className={`line-clamp-2 text-gray-500`}>
            {files.find((file) => file.document_id.toString() === documentId)?.document_name}
          </span>
        </td>
        <td className="px-2 md:px-3 py-3">
          <div className="flex h-full">
            {comparedFetched ? (
              totalDiscrepancies[documentId] > 0 ? (
                <div className="flex items-center justify-center text-yellow-600">
                  <p className="text-xs">{totalDiscrepancies[documentId]} issues</p>
                </div>
              ) : (
                <div className="flex items-center justify-center text-green-600">
                  <p className="text-xs">No issues</p>
                </div>
              )
            ) : (
              <p className="text-gray-400">-</p>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const EmptyTableCell = () => (
    <tr className={`transition text-xs animate-pulse`}>
      <td className="px-2 md:px-3 py-3 border-gray-100">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
      <td className="px-2 md:px-3 py-3">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
      <td className="px-2 md:px-3 py-2 space-y-0.5">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
    </tr>
  );

  const ActionButton = () => {
    if (splitterFetched || initiallyFetched)
      return (
        <div className="flex justify-between items-center">
          <button
            className="bg-gray-100 text-gray-600 hover:text-blue-500 text-sm font-medium h-9 border-none flex items-center justify-center rounded-lg transition space-x-2 px-4"
            onClick={() => setCreateFolderModalOpen(true)}
          >
            <FiUpload />
            <p className="text-xs">Upload Shipping Documents</p>
          </button>
        </div>
      );
  };

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <AddFilesModal
        open={isCreateFolderModalOpen}
        setOpen={setCreateFolderModalOpen}
        archiveId={projectId}
        getArchive={getArchive}
        getFiles={getFiles}
        setExtractFetched={setExtractFetched}
        setSplitterFetched={setSplitterFetched}
        setComparedFetched={setComparedFetched}
        setInitiallyFetched={setInitiallyFetched}
      />
      <FolderDashboardHeader
        title="Shipping Documents"
        subtitle="Including L/C, D/I, B/L, C/O, C/I, P/L, and any other certificates."
        action={<ActionButton />}
      />

      <table style={{ tableLayout: "fixed" }} className="w-full bg-white">
        <tbody className="divide-y divide-gray-100">
          {Object.entries(splitterData).map(([documentId, items], index) =>
            items.map((item, itemIndex) => {
              return (
                <TableCell
                  key={`${documentId}-${itemIndex}`}
                  item={item}
                  splitId={itemIndex}
                  index={index}
                  documentId={documentId}
                />
              );
            })
          )}
          {!comparedFetched && <EmptyTableCell />}
        </tbody>
      </table>
    </div>
  );
};

export default TradeDocumentsTable;

import React, { useEffect, useState } from "react";
import { FaCaretDown, FaCheck } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { formatDocumentType } from "../../utils/formatDocumentType";

const CrossCheckIcoTable = ({ currentComparedA, currentDocumentType, type }) => {
  const [isCollapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (currentComparedA?.lot_number?.some((item) => !item?.target_detail || !item?.ref_detail)) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
    return () => {};
  }, [currentComparedA]);

  const hasError = currentComparedA?.lot_number?.some((item) => !item?.target_detail || !item?.ref_detail);

  const headerCss = `rounded transition flex items-center space-x-1 transition py-1.5 px-2 w-full`;
  const headerTitleCss = `text-black text-sm font-medium`;
  const checkIconCss = "text-green-600 w-5 h-3 flex-shrink-0";
  const warningIconCss = "text-yellow-600 w-5 h-4 flex-shrink-0";
  const arrowIconCss = `${isCollapsed ? "-rotate-90" : ""} text-gray-500 transform transition`;
  const tableContainerCss = "border rounded overflow-hidden mt-2 mb-6";
  const tableCss = "w-full text-sm text-left overflow-hidden divide-y";
  const tableBodyCss = "divide-y text-xs";

  const TableHeader = () => (
    <thead className="text-gray-600 bg-gray-100 text-xs text-left">
      <tr>
        <th className="px-2 py-3 font-medium border-r">Description</th>
        <th className="px-2 py-3 font-medium border-r">
          {currentDocumentType.includes("bol")
            ? "Bill of Lading"
            : currentDocumentType.includes("coo_general")
            ? "Certificate of Origin"
            : currentDocumentType.includes("coo_ico")
            ? "Certificate of Origin (ICO)"
            : currentDocumentType.includes("coo_ldc")
            ? "Certificate of Origin (LDC)"
            : currentDocumentType.includes("coo_free_trade")
            ? "Certificate of Origin (FTA)"
            : currentDocumentType.includes("coo_gsp")
            ? "Certificate of Origin (GSP)"
            : formatDocumentType(currentDocumentType)}
        </th>
        <th className="px-2 py-3 font-medium bg-gray-200">
          {type === "bol"
            ? "Bill of Lading"
            : type === "invoice"
            ? "Commercial Invoice"
            : type === "contract"
            ? "Contract"
            : type === "di"
            ? "Document Instruction"
            : "Letter of Credit"}
        </th>
      </tr>
    </thead>
  );

  if (!currentComparedA?.lot_number || currentComparedA?.lot_number?.length === 0)
    return (
      <div className="w-full">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            {!hasError ? <FaCheck className={checkIconCss} /> : <IoIosWarning className={warningIconCss} />}
            <p className={headerTitleCss}>LOT numbers (0)</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="w-full h-8 text-sm text-left text-gray-400 flex items-center pl-10 rounded overflow-hidden divide-y mb-6 mt-2">
            No LOT number issues were found.
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="w-full">
        <button onClick={() => setCollapsed(!isCollapsed)} className={headerCss}>
          <div className="flex space-x-2 items-center">
            <FaCaretDown className={arrowIconCss} />
            {!hasError ? <FaCheck className={checkIconCss} /> : <IoIosWarning className={warningIconCss} />}
            <p className={headerTitleCss}>LOT numbers ({currentComparedA?.lot_number?.length})</p>
          </div>
        </button>
        {!isCollapsed && (
          <div className="pl-7 pr-2 max-w-screen-xl">
            <div className={tableContainerCss}>
              <table style={{ tableLayout: "fixed" }} className={tableCss}>
                <TableHeader />
                <tbody className={tableBodyCss}>
                  {currentComparedA?.lot_number?.map((item, index) => (
                    <tr key={index} className="divide-x">
                      <td className="font-medium py-3 divide-y">
                        <div className="flex px-3 h-1/2 items-center">
                          <div
                            className={`${
                              !item?.target_detail || !item?.ref_detail ? "text-yellow-600" : "text-green-600"
                            }`}
                          >
                            {!item?.target_detail || !item?.ref_detail ? "Recheck container" : "Match"}
                          </div>
                        </div>
                      </td>
                      <td className="font-medium py-3 divide-y">
                        <div className="flex px-3 h-1/2 items-center">
                          <div className={`${!item?.target_detail && ""}`}>{item?.target_detail || "-"}</div>
                        </div>
                      </td>
                      <td className="py-3 divide-y bg-gray-100 text-gray-500">
                        <div className={`flex px-3 h-1/2 items-center ${!item?.ref_detail && ""}`}>
                          <div>{item?.ref_detail || "-"}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
};

export default CrossCheckIcoTable;

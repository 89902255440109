import React, { useState } from "react";
import FolderDashboardHeader from "./FolderDashboard/FolderDashboardHeader";
import { FiUpload } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AddContractModal from "../modals/AddContractModal";

const ContractTable = ({
  archiveInfo,
  splitterData,
  files,
  comparedFetched,
  contractsData,
  projectId,
  getArchive,
  getFiles,
  setExtractFetched,
  setSplitterFetched,
  setComparedFetched,
  setInitiallyFetched,
}) => {
  const navigate = useNavigate();
  const [isAddContractModalOpen, setAddContractModalOpen] = useState(false);

  const allContractDocumentIds = archiveInfo?.contracts?.map((contract) => contract.document_id) || [];

  const getDiscrepancies = (archiveInfo, splitId) => {
    const status = archiveInfo?.archive_compared_status || {};

    const calculateDiscrepancies = (documentId, splitId, data) => {
      const contractsDiscrepancies =
        data?.contractsData?.[documentId]?.compared_b?.filter((item) => item.discrepancy)?.length || 0;

      return contractsDiscrepancies;
    };

    const discrepancies = Object.entries(status).reduce((acc, [type, statusObj]) => {
      Object.entries(statusObj).forEach(([key, value]) => {
        const constructedKey = `${key}_${value}`;
        const discrepancyCount = calculateDiscrepancies(constructedKey, splitId, {
          contractsData,
        });
        acc[constructedKey] = discrepancyCount;
      });

      return acc;
    }, {});

    return discrepancies;
  };

  const mergeDiscrepancies = (discrepancies) => {
    return Object.entries(discrepancies).reduce((acc, [key, value]) => {
      const documentId = key.split("_")[0];
      acc[documentId] = (acc[documentId] || 0) + value;
      return acc;
    }, {});
  };

  const ActionButton = () => {
    return (
      <div className="flex justify-between items-center">
        <button
          onClick={() => setAddContractModalOpen(true)}
          className="bg-gray-100 text-gray-600 hover:text-blue-500 text-sm font-medium h-9 border-none flex items-center justify-center rounded-lg transition space-x-2 px-4"
        >
          <FiUpload />
          <p className="text-xs">Upload Contracts</p>
        </button>
      </div>
    );
  };

  const EmptyTableCell = () => (
    <tr className={`transition text-xs animate-pulse`}>
      <td className="px-2 md:px-3 py-3 border-gray-100">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
      <td className="px-2 md:px-3 py-3">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
      <td className="px-2 md:px-3 py-2 space-y-0.5">
        <div className="h-4 bg-slate-200 rounded w-full"></div>
      </td>
    </tr>
  );

  const TableCell = ({ item, documentId, splitId }) => {
    const getExtensionByDocumentId = (documentId) => {
      const file = files.find((file) => file.document_id.toString() === documentId.toString());
      if (file) {
        const extension = file.document_name.split(".").pop();
        return extension;
      }
      return "";
    };

    const initialDiscrepancies = getDiscrepancies(archiveInfo, splitId, contractsData);
    const totalDiscrepancies = mergeDiscrepancies(initialDiscrepancies);

    return (
      <tr
        onClick={() =>
          comparedFetched &&
          navigate(
            `/dashboard/folders/${projectId}/check-contract-results?document_id=${documentId}&split_id=${splitId}&doc_type=${
              item?.document_type
            }&extension=${getExtensionByDocumentId(documentId)}&tab=1`
          )
        }
        className={`transition text-xs hover:bg-sky-100 cursor-pointer`}
      >
        <td className="px-2 md:px-3 py-3 border-gray-100">
          <div className="space-x-2 flex items-center">
            {!comparedFetched && (
              <AiOutlineLoading3Quarters className="w-3 h-3 animate-spin text-gray-500 flex-shrink-0" />
            )}
            <button
              className={`${
                !comparedFetched ? "text-gray-400 font-normal" : "text-sky-600"
              } hover:underline text-left flex-shrink-0`}
            >
              {archiveInfo?.contracts?.find((contract) => contract.document_id.toString() === documentId.toString())
                ?.contract_type === "SALES"
                ? "Sales Contract"
                : archiveInfo?.contracts?.find((contract) => contract.document_id.toString() === documentId.toString())
                    ?.contract_type === "PURCHASE"
                ? "Purchase Contract"
                : ""}
            </button>
          </div>
        </td>

        <td className="px-2 md:px-3 py-3 space-y-0.5 rounded-tr rounded-br">
          <span className={`line-clamp-2 text-gray-500`}>
            {files.find((file) => file.document_id.toString() === documentId)?.document_name}
          </span>
        </td>
        <td className="px-2 md:px-3 py-3">
          <div className="flex h-full">
            {comparedFetched ? (
              totalDiscrepancies[documentId] > 0 ? (
                <div className="flex items-center justify-center text-yellow-600">
                  <p className="text-xs">{totalDiscrepancies[documentId]} issues</p>
                </div>
              ) : (
                <div className="flex items-center justify-center text-green-600">
                  <p className="text-xs">No issues</p>
                </div>
              )
            ) : (
              <p className="text-gray-400">-</p>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const filterContractsByType = (contracts) => {
    const filteredContracts = {};
    contracts.forEach((contract) => {
      const { contract_type, document_id } = contract;
      if (!filteredContracts[contract_type] || filteredContracts[contract_type].document_id < document_id) {
        filteredContracts[contract_type] = contract;
      }
    });
    return Object.values(filteredContracts);
  };

  const filteredContracts = filterContractsByType(archiveInfo?.contracts || []);

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow">
      <AddContractModal
        open={isAddContractModalOpen}
        setOpen={setAddContractModalOpen}
        archiveId={projectId}
        getArchive={getArchive}
        getFiles={getFiles}
        setExtractFetched={setExtractFetched}
        setSplitterFetched={setSplitterFetched}
        setComparedFetched={setComparedFetched}
        setInitiallyFetched={setInitiallyFetched}
      />
      <FolderDashboardHeader
        title="Contracts"
        action={<ActionButton />}
        subtitle={"Compare your Sales Contract & Purchase Contract"}
      />
      <table style={{ tableLayout: "fixed" }} className="w-full bg-white">
        <tbody className="divide-y divide-gray-100">
          {Object.entries(splitterData)
            .filter(([documentId]) => allContractDocumentIds.includes(parseInt(documentId)))
            .map(([documentId, items], index) =>
              items.map((item, itemIndex) => {
                const contract = filteredContracts.find((contract) => contract.document_id === parseInt(documentId));
                if (contract) {
                  return (
                    <TableCell
                      key={`${documentId}-${itemIndex}`}
                      item={item}
                      splitId={itemIndex}
                      index={index}
                      documentId={documentId}
                    />
                  );
                }
                return null;
              })
            )}
          {!comparedFetched && <EmptyTableCell />}
        </tbody>
      </table>
    </div>
  );
};

export default ContractTable;

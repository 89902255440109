import React, { useState, useRef, useEffect, useCallback } from "react";
import "./index.css";
import { formatDocumentType } from "../../utils/formatDocumentType";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import CrossCheckList from "./CrossCheckList";
import useFetchJson from "../../hooks/useFetchJson";
import ExtractLetterOfCreditResults from "./ExtractLetterOfCreditResults";
import { TfiBackLeft } from "react-icons/tfi";
import PdfThumbnail from "./PdfThumbnail";
import moment from "moment";
import CrossCheckPdf from "./CrossCheckPdf";
import { MdCancel } from "react-icons/md";
import ExtractDocumentInstructionResult from "./ExtractDocumentInstructionResult";
import { Tabs } from "antd";
import { IoIosWarning } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import ExtractResults from "./ExtractResults";

const CrossCheckResult = () => {
  const { fetchJson } = useFetchJson();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const documentIdPath = searchParams.get("document_id");
  const splitIdPath = parseInt(searchParams.get("split_id"));
  const documentTypePath = searchParams.get("doc_type");
  const extensionType = searchParams.get("extension");
  const currentTab = searchParams.get("tab") || 1;

  const pdfRefCompare = useRef(null);
  const [archiveInfo, setArchiveInfo] = useState(null);

  const fetchedSplitDocumentIds = useRef(new Set());
  const fetchedExtractDocumentIds = useRef(new Set());
  const fetchedComparedDocumentIds = useRef(new Set());
  const fetchedComparedInvoiceDocumentIds = useRef(new Set());
  const fetchedContractDocumentIds = useRef(new Set());
  const fetchedComparedLcDocumentIds = useRef(new Set());
  const fetchedComparedDiDocumentIds = useRef(new Set());

  const [splitterData, setSplitterData] = useState({});
  const [extractData, setExtractData] = useState({});
  const [comparedData, setComparedData] = useState({});
  const [comparedInvoiceData, setComparedInvoiceData] = useState({});
  const [contractData, setContractData] = useState({});
  const [comparedLcData, setComparedLcData] = useState({});
  const [comparedDiData, setComparedDiData] = useState({});

  const [files, setFiles] = useState([]);
  const [splitterFetched, setSplitterFetched] = useState(false);
  const [filesFetched, setFilesFetched] = useState(false);

  const [highlightedIndicies, setHighlightedIndicies] = useState([]);

  const { getFiles, getArchive } = useFetchData(setFiles, setArchiveInfo, setFilesFetched);

  useEffect(() => {
    getArchive(projectId);
  }, [getArchive, projectId]);

  useEffect(() => {
    getFiles(projectId);
  }, [getFiles, projectId]);

  useEffect(() => {
    // Function to fetch split data
    const fetchSplitData = async () => {
      const fetchPromises = files.map((file) => {
        if (!fetchedSplitDocumentIds.current.has(file.document_id)) {
          return fetchJson("split", file.archive_id, file.document_id, fetchedSplitDocumentIds, setSplitterData);
        }
        return Promise.resolve();
      });
      await Promise.all(fetchPromises);
      const allFetched = files.every((file) => splitterData.hasOwnProperty(file.document_id));
      if (allFetched) {
        setSplitterFetched(true);
      } else {
        setSplitterFetched(false);
      }
    };

    const fetchExtractData = async () => {
      const fetchPromises = files.map((file) => {
        if (!fetchedExtractDocumentIds.current.has(file.document_id)) {
          return fetchJson("extracted", file.archive_id, file.document_id, fetchedExtractDocumentIds, setExtractData);
        }
        return Promise.resolve();
      });
      await Promise.all(fetchPromises);
    };

    if (filesFetched && files.length > 0) {
      fetchSplitData();
      fetchExtractData();
    }
    return () => {};
  }, [files, fetchJson, splitterData, setSplitterFetched, filesFetched, extractData]);

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const fetchComparedDataOnce = useCallback(async () => {
    if (isEmptyObject(archiveInfo?.archive_compared_status)) return;

    const bolStatus = archiveInfo?.archive_compared_status?.bol || null;
    const invoiceStatus = archiveInfo?.archive_compared_status?.commercial_invoice || null;
    const contractStatus = archiveInfo?.archive_compared_status?.contract || null;
    const lcStatus = archiveInfo?.archive_compared_status?.letter_of_credit || null;
    const diStatus = archiveInfo?.archive_compared_status?.document_instruction || null;

    const fetchStatusData = async (type, status, fetchedIds, setData) => {
      if (status) {
        const keysValues = Object.entries(status).map(([key, value]) => `${key}_${value}`);
        const fetchPromises = Object.entries(status).map(async ([key, value]) => {
          const kv = `${key}_${value}`;
          if (!fetchedIds.current.has(kv)) {
            await fetchJson(type, archiveInfo?.archive_id, kv, fetchedIds, setData);
            fetchedIds.current.add(kv); // Ensure the set is updated
          }
        });
        await Promise.all(fetchPromises);
        return keysValues.every((kv) => fetchedIds.current.has(kv));
      } else {
        return true;
      }
    };
    await fetchStatusData("compared", bolStatus, fetchedComparedDocumentIds, setComparedData);
    await fetchStatusData("comparedInvoice", invoiceStatus, fetchedComparedInvoiceDocumentIds, setComparedInvoiceData);
    await fetchStatusData("contract", contractStatus, fetchedContractDocumentIds, setContractData);
    await fetchStatusData("lc", lcStatus, fetchedComparedLcDocumentIds, setComparedLcData);
    await fetchStatusData("di", diStatus, fetchedComparedDiDocumentIds, setComparedDiData);
  }, [archiveInfo?.archive_id, archiveInfo?.archive_compared_status, fetchJson]);

  // FETCH COMPARE
  useEffect(() => {
    if (splitterFetched) {
      fetchComparedDataOnce();
    }
    return () => {};
  }, [files, fetchJson, comparedData, comparedInvoiceData, contractData, splitterFetched, fetchComparedDataOnce]);

  const Pages = ({ documentId, splitId, item }) => {
    const getExtensionByDocumentId = (documentId) => {
      const file = files.find((file) => file.document_id.toString() === documentId.toString());
      if (file) {
        const extension = file.document_name.split(".").pop();
        return extension;
      }
      return "";
    };

    return (
      <button
        onClick={() =>
          setSearchParams({
            document_id: documentId,
            split_id: splitId,
            doc_type: item?.document_type,
            extension: getExtensionByDocumentId(documentId),
            tab: 1,
          })
        }
        className={`${
          documentIdPath === documentId && splitIdPath === splitId
            ? "bg-gray-500 text-white"
            : "text-gray-200 hover:bg-gray-600"
        } flex-shrink-0 flex h-10 items-center px-2 text-xs space-x-2 transition w-full`}
      >
        <p className="truncate text-center">
          {item?.document_type.includes("bol")
            ? "Bill of Lading"
            : item?.document_type.includes("coo_ico")
            ? "Certificate of Origin (ICO)"
            : item?.document_type.includes("coo_ldc")
            ? "Certificate of Origin (LDC)"
            : item?.document_type.includes("coo_free_trade")
            ? "Certificate of Origin (FTA)"
            : item?.document_type.includes("coo_gsp")
            ? "Certificate of Origin (GSP)"
            : formatDocumentType(item?.document_type)}
        </p>
      </button>
    );
  };

  const getDiscrepancies = (
    archiveInfo,
    comparedData,
    comparedInvoiceData,
    contractData,
    comparedLcData,
    comparedDiData,
    splitId
  ) => {
    const status = archiveInfo?.archive_compared_status || {};

    const calculateDiscrepancies = (documentId, splitId, data) => {
      const contractDiscrepancies =
        data?.contractData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const bolDiscrepancies =
        data?.comparedData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const dateDiscrepancy = data?.comparedData?.[documentId]?.[splitId]?.compared_a?.issued_date?.is_error
        ? 1
        : 0;
      const invoiceDiscrepancies =
        data?.comparedInvoiceData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const lcDiscrepancies =
        data?.comparedLcData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;
      const diDiscrepancies =
        data?.comparedDiData?.[documentId]?.[splitId]?.compared_b?.filter((item) => item.discrepancy)
          ?.length || 0;

      return (
        contractDiscrepancies +
        bolDiscrepancies +
        invoiceDiscrepancies +
        dateDiscrepancy +
        lcDiscrepancies +
        diDiscrepancies
      );
    };

    const discrepancies = Object.entries(status).reduce((acc, [type, statusObj]) => {
      Object.entries(statusObj).forEach(([key, value]) => {
        const constructedKey = `${key}_${value}`;
        const discrepancyCount = calculateDiscrepancies(constructedKey, splitId, {
          contractData,
          comparedData,
          comparedInvoiceData,
          comparedLcData,
          comparedDiData,
        });
        acc[constructedKey] = discrepancyCount;
      });

      return acc;
    }, {});

    return discrepancies;
  };

  const mergeDiscrepancies = (discrepancies) => {
    return Object.entries(discrepancies).reduce((acc, [key, value]) => {
      const documentId = key.split("_")[0];
      acc[documentId] = (acc[documentId] || 0) + value;
      return acc;
    }, {});
  };

  const initialDiscrepancies = getDiscrepancies(
    archiveInfo,
    comparedData,
    comparedInvoiceData,
    contractData,
    comparedLcData,
    comparedDiData,
    splitIdPath
  );

  const totalDiscrepancies = mergeDiscrepancies(initialDiscrepancies);

  const items = [
    {
      key: "1",
      label: (
        <div className="px-6 flex items-center space-x-1.5">
          <p>Information</p>
        </div>
      ),
      children: (
        <div className="-mt-4">
          <ExtractResults documentId={documentIdPath} extractData={extractData} splitIdPath={splitIdPath} />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="px-6 flex items-center space-x-1.5">
          {totalDiscrepancies[documentIdPath] > 0 ? (
            <IoIosWarning className="text-yellow-500 w-5 h-5" />
          ) : (
            <FaCheck className="w-3.5 h-3.5 text-green-600" />
          )}
          <p>Check Results</p>
        </div>
      ),
      children: (
        <div className="-mt-4">
          {documentTypePath === "letter_of_credit" ? (
            <ExtractLetterOfCreditResults splitterData={splitterData} files={files} splitterFetched={splitterFetched} />
          ) : documentTypePath === "document_instruction" ? (
            <ExtractDocumentInstructionResult
              splitterData={splitterData}
              files={files}
              splitterFetched={splitterFetched}
            />
          ) : (
            <>
              {!documentTypePath?.includes("letter_of_credit") && (
                <CrossCheckList
                  type={"lc"}
                  comparedData={comparedLcData}
                  documentIdPath={documentIdPath}
                  splitIdPath={splitIdPath}
                  documentTypePath={documentTypePath}
                  highlightedIndicies={highlightedIndicies}
                  setHighlightedIndicies={setHighlightedIndicies}
                />
              )}
              {!documentTypePath?.includes("document_instruction") && (
                <CrossCheckList
                  type={"di"}
                  comparedData={comparedDiData}
                  documentIdPath={documentIdPath}
                  splitIdPath={splitIdPath}
                  documentTypePath={documentTypePath}
                  highlightedIndicies={highlightedIndicies}
                  setHighlightedIndicies={setHighlightedIndicies}
                />
              )}
              {!documentTypePath?.includes("bol") && (
                <CrossCheckList
                  type={"bol"}
                  comparedData={comparedData}
                  documentIdPath={documentIdPath}
                  splitIdPath={splitIdPath}
                  documentTypePath={documentTypePath}
                  highlightedIndicies={highlightedIndicies}
                  setHighlightedIndicies={setHighlightedIndicies}
                />
              )}
              {!documentTypePath?.includes("invoice") && (
                <CrossCheckList
                  type={"invoice"}
                  comparedData={comparedInvoiceData}
                  documentIdPath={documentIdPath}
                  splitIdPath={splitIdPath}
                  documentTypePath={documentTypePath}
                  highlightedIndicies={highlightedIndicies}
                  setHighlightedIndicies={setHighlightedIndicies}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const changeTab = (e) => {
    setSearchParams({
      document_id: documentIdPath,
      split_id: splitIdPath,
      doc_type: documentTypePath,
      extension: extensionType,
      tab: e,
    });
    const newUrl = `${window.location.pathname}?document_id=${documentIdPath}&split_id=${splitIdPath}&doc_type=${documentTypePath}&extension=${extensionType}&tab=${e}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  return (
    <div className="relative bg-gray-200 flex w-full" style={{ height: "100svh" }}>
      {highlightedIndicies.length > 0 && (
        <div className="absolute w-full h-full bg-black bg-opacity-60 z-50">
          <button
            onClick={() => setHighlightedIndicies([])}
            className="absolute text-gray-200 hover:text-white top-2 right-4 z-50"
          >
            <MdCancel className="w-10 h-10" />
          </button>

          <CrossCheckPdf
            projectId={projectId}
            searchParams={searchParams}
            pdfRefCompare={pdfRefCompare}
            comparedData={comparedData}
            contractData={contractData}
            splitterData={splitterData}
            comparedInvoiceData={comparedInvoiceData}
            comparedLcData={comparedLcData}
            comparedDiData={comparedDiData}
            highlightedIndicies={highlightedIndicies}
            extensionType={extensionType}
            setHighlightedIndicies={setHighlightedIndicies}
          />
        </div>
      )}

      <div className="flex flex-col flex-shrink-0 bg-gray-800 transition w-40 md:w-48" style={{ height: "100svh" }}>
        <div className="h-full">
          <Link to="/" className="py-3">
            <button className="flex items-center mx-3 my-4">
              <img
                src={"https://s3.traddocs.com/landing/logo_name_red.png"}
                alt=""
                className="h-4 md:h-4 object-contain"
                draggable={false}
                style={{ filter: "brightness(0) invert(1)" }}
              />
            </button>
          </Link>
          <button
            onClick={() => navigate(`/dashboard/folders/${projectId}`)}
            className={`text-sky-300 flex-shrink-0 flex h-10 items-center px-2 text-xs space-x-2 transition w-full bg-gray-700 hover:bg-gray-600`}
          >
            <TfiBackLeft />
            <p className="truncate text-center">Back to workspace</p>
          </button>
          {Object.keys(splitterData).map((key, outerIndex) =>
            splitterData[key]
              .filter((item) => item.document_type !== "contract")
              .map((item, innerIndex) => <Pages key={innerIndex} item={item} documentId={key} splitId={innerIndex} />)
          )}
        </div>
      </div>
      <div className="w-full relative flex justify-start flex-col bg-white overflow-y-auto">
        <div className="w-full flex-shrink-0 flex p-3 items-center">
          <PdfThumbnail
            projectId={projectId}
            searchParams={searchParams}
            splitterData={splitterData}
            extensionType={extensionType}
            setHighlightedIndicies={setHighlightedIndicies}
          />
          <div className="pl-3">
            <div className="font-medium text-lg mb-1">
              {documentTypePath?.includes("bol")
                ? "Bill of Lading"
                : documentTypePath?.includes("coo_general")
                ? "Certificate of Origin"
                : documentTypePath?.includes("coo_ico")
                ? "Certificate of Origin (ICO)"
                : documentTypePath?.includes("coo_ldc")
                ? "Certificate of Origin (LDC)"
                : documentTypePath?.includes("coo_free_trade")
                ? "Certificate of Origin (FTA)"
                : documentTypePath?.includes("coo_gsp")
                ? "Certificate of Origin (GSP)"
                : formatDocumentType(documentTypePath)}
            </div>
            <p className={`${totalDiscrepancies[documentIdPath] > 0 ? "text-yellow-600" : "text-green-600"} text-xs`}>
              {totalDiscrepancies[documentIdPath] || 0} issues found
            </p>
            <p className={`text-gray-400 text-xs mt-4`}>
              {files.find((file) => file.document_id.toString() === documentIdPath)?.document_name} (p.
              {splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices &&
                (splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices.length > 1
                  ? `${splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices[0]}-${
                      splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices[
                        splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices.length - 1
                      ]
                    }`
                  : splitterData?.[documentIdPath]?.[splitIdPath]?.traddocs_watermark_indices[0])}
              )
            </p>
            <p className="text-xs text-gray-400 mt-1">
              Uploaded:{" "}
              {moment(files.find((file) => file.document_id.toString() === documentIdPath)?.document_created_at).format(
                "MMM DD, YYYY"
              )}
            </p>
          </div>
        </div>
        <Tabs
          activeKey={`${currentTab}`}
          onChange={changeTab}
          items={items}
          tabBarStyle={{ paddingLeft: "10px" }}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default CrossCheckResult;
